import styled from 'styled-components';
import { MdCloudDone, MdMultilineChart, MdStyle, MdVerifiedUser, MdDone } from "react-icons/md";

export const CloudIcon = styled(MdCloudDone)`
  color: #7764E4 ;
  font-size: 26px;
  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) { 
    font-size: 50px;
   }
  // X-Large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) { 
    font-size: 50px;
   }
  // XX-Large devices (larger desktops, 1400px and up)
  @media (min-width: 1400px) { 
    font-size: 50px;
   }
`;
export const FastIcon = styled(MdMultilineChart)`
  color: #7764E4 ;
  font-size: 26px;
  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) { 
    font-size: 50px;
   }
  // X-Large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) { 
    font-size: 50px;
   }
  // XX-Large devices (larger desktops, 1400px and up)
  @media (min-width: 1400px) { 
    font-size: 50px;
   }
`;
export const StyleIcon = styled(MdStyle)`
  color: #7764E4 ;
  font-size: 26px;
  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) { 
    font-size: 50px;
   }
  // X-Large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) { 
    font-size: 50px;
   }
  // XX-Large devices (larger desktops, 1400px and up)
  @media (min-width: 1400px) { 
    font-size: 50px;
   }
`;
export const ShieldIcon = styled(MdVerifiedUser)`
  color: #7764E4 ;
  font-size: 26px;
  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) { 
    font-size: 50px;
   }
  // X-Large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) { 
    font-size: 50px;
   }
  // XX-Large devices (larger desktops, 1400px and up)
  @media (min-width: 1400px) { 
    font-size: 50px;
   }
`;
export const DoneIcon = styled(MdDone)`
  color: #3A3C53;
  font-size: 16px !important;
  margin-right: 10px;
  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) { 
  font-size: 20px !important;
   }
  // X-Large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) { 
  font-size: 20px !important;
   }
  // XX-Large devices (larger desktops, 1400px and up)
  @media (min-width: 1400px) { 
  font-size: 20px !important;
   }
`;