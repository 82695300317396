import * as SC from "../../../../styles/styles";

import { Input } from "../../../../components/Input";
import { LoadingComponent } from "../../../../components/Loading";
import React from "react";
import { Select } from "../../../../components/Select";
import { TextArea } from "../../../../components/TextArea";
import useFormContato from "./hooks/useFormContato";
import { useHomePage } from "../../hooks/useHomePage";
import useLoaderDataContato from "./hooks/useLoaderDataContato";

const Contato = () => {
    const { state } = useHomePage();

    const { onSubmit, handleSelectValueChange, handleInputValueChange } =
        useFormContato();

    const { estados, municipios } = useLoaderDataContato();

    return (
        <>
            {state.loading && (
                <LoadingComponent fixed background="#f5f5f5" color="#7764e4" />
            )}

            <form onSubmit={onSubmit}>
                <SC.FormContainer>
                    <Input
                        label="Nome"
                        name="nome"
                        type="text"
                        value={state.form.nome}
                        onValueChange={handleInputValueChange}
                    />
                    <Input
                        label="Telefone"
                        name="telefone"
                        type="phone"
                        value={state.form.telefone}
                        onValueChange={handleInputValueChange}
                    />
                    <Input
                        label="Email"
                        name="email"
                        type="email"
                        value={state.form.email}
                        onValueChange={handleInputValueChange}
                    />
                    <Input
                        label="Motivo"
                        name="motivo"
                        type="text"
                        value={state.form.motivo}
                        onValueChange={handleInputValueChange}
                    />
                    <TextArea
                        label="Assunto"
                        name="assunto"
                        value={state.form.assunto}
                        onValueChange={handleInputValueChange}
                    />
                    {/* <Select
                        label="Estado"
                        name="estado"
                        value={state.form.estado}
                        onValueChange={handleSelectValueChange}
                    >
                        {estados?.map((estado) => (
                            <option
                                key={estado.seq_estado}
                                value={estado.seq_estado}
                            >
                                {estado.nom_estado}
                            </option>
                        ))}
                    </Select>
                    <Select
                        label="Município"
                        name="municipio"
                        value={state.form.municipio}
                        onValueChange={handleSelectValueChange}
                    >
                        {municipios?.map((municipio) => (
                            <option
                                key={municipio.seq_municipio}
                                value={municipio.seq_municipio}
                            >
                                {municipio.nom_municipio}
                            </option>
                        ))}
                    </Select> */}
                </SC.FormContainer>
                <SC.FormContent>
                    <label> Ao enviar você automaticamente estará concordando com os termos de privacidade e compartilhamento de informações</label>
                    <button type="submit">Enviar</button>                                
                </SC.FormContent>
            </form>
        </>
    );
};

export default Contato;
