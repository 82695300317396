import React from 'react'
import * as SC from './styles';
import { IoChevronForwardOutline } from "react-icons/io5";

export default function TextPack({title, subtitle}) {
  return (
    <>
      <SC.Container>
        <SC.Title>
          {title}
        </SC.Title>
        <SC.Subtitle>
          {subtitle}
        </SC.Subtitle>
        {/* <SC.ClickTitle>
          Saiba mais
          <IoChevronForwardOutline />
        </SC.ClickTitle> */}
      </SC.Container>
    </>
  )
}