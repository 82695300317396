import Toast from "../../../../../components/Toast";
import { entrarEmContatoServico } from "../../../../../services/contato";
import { useCallback } from "react";
import { useHomePage } from "../../../hooks/useHomePage";

const useFormContato = () => {
    const { state, actions } = useHomePage();

    const onSubmit = useCallback(
        async (event) => {
            event.preventDefault();

            try {
                const { nome, telefone, email, motivo, assunto } =
                    state.form;

                if (!nome) {
                    return Toast({
                        type: "error",
                        message: "Nome é obrigatório",
                    });
                }

                if (!telefone) {
                    return Toast({
                        type: "error",
                        message: "Telefone é obrigatório",
                    });
                }

                if (!email) {
                    return Toast({
                        type: "error",
                        message: "Email é obrigatório",
                    });
                }

                if (!motivo) {
                    return Toast({
                        type: "error",
                        message: "Motivo é obrigatório",
                    });
                }

                if (!assunto) {
                    return Toast({
                        type: "error",
                        message: "Assunto é obrigatório",
                    });
                }

                const formData = {
                    nome,
                    telefone,
                    email,
                    motivo,
                    assunto,
                    municipio: 109,
                };

                actions.setLoading(true);

                await entrarEmContatoServico(formData);

                actions.setLoading(false);

                Toast({
                    type: "success",
                    message: "Enviado com sucesso",
                });
            } catch (err) {
                actions.setLoading(false);
                Toast({
                    type: "error",
                    message: err?.response?.data?.message,
                });
            }
        },
        [state.form, actions.setLoading]
    );

    const handleSelectValueChange = useCallback(
        (data) => {
            actions.setForm((oldValue) => ({
                ...oldValue,
                [data.name]: data.numberValue,
            }));
        },
        [actions.setForm]
    );

    const handleInputValueChange = useCallback(
        (data) => {
            actions.setForm((oldValue) => ({
                ...oldValue,
                [data.name]: data.value,
            }));
        },
        [actions.setForm]
    );

    return {
        onSubmit,
        handleSelectValueChange,
        handleInputValueChange,
    };
};

export default useFormContato;
