import React, { createContext, useContext, useMemo, useState } from "react";

const HomePageContext = createContext({});

export const HomePageProvider = ({ children }) => {
    const [loading, setLoading] = useState(false);

    const [form, setForm] = useState({
        nome: "",
        telefone: "",
        email: "",
        motivo: "",
        assunto: "",
        estado: 2,
        municipio: 109,
    });

    const value = useMemo(
        () => ({
            state: {
                loading,
                form,
            },
            actions: {
                setLoading,
                setForm,
            },
        }),
        [loading, setLoading, form, setForm]
    );

    return (
        <HomePageContext.Provider value={value}>
            {children}
        </HomePageContext.Provider>
    );
};

export const useHomePage = () => {
    return useContext(HomePageContext);
};
