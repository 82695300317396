import * as SC from "../../styles/styles";
import * as Icons from "../../styles/icons";

import React from "react";

export const PoliticaOperador = () => {
    return (
        <>
            <SC.AboutContainer>
                {/* <SC.ProductSpaceContainer>
                    <SC.Text1>Políticas</SC.Text1>
                    <SC.Text2>
                        Todas as políticas
                    </SC.Text2>
                </SC.ProductSpaceContainer> */}
            </SC.AboutContainer>
            <SC.ListWhite>
                <SC.ListHolder>
                    {/* <SC.Text4>Privacidade</SC.Text4> */}
                    <SC.Text3>Tipos de dados coletados</SC.Text3>
                    <SC.TextP>
                        &emsp;&emsp;&emsp;Entre os tipos de Dados Pessoais que o Operador coleta, por si ou por meio de terceiros, estão: Dados de Uso, e-mail, nome, telefone e localização.

                        <br />&emsp;&emsp;&emsp;Os Dados Pessoais podem ser fornecidos pelo Usuário ou, no caso de Dados de Uso, coletados automaticamente ao usar o aplicativo Operador. A menos que especificado de outra forma, todos os dados solicitados pelo aplicativo são obrigatórios e a falha em fornecer esses dados pode impossibilitar que o Operador forneça seus serviços.

                        <br />&emsp;&emsp;&emsp;Qualquer uso de ferramentas de rastreamento pelo aplicativo Operador ou pelos proprietários de serviços de terceiros usados no mesmo atende à finalidade de fornecer o Serviço exigido pelo Usuário.

                        <br />&emsp;&emsp;&emsp;O aplicativo Operador necessita da sua localização para registros internos geolocalizados, esses processos são restritamente internos e garantem a fidelidade das informações fornecidas pelos usuários. Já para a utilização de rotas de trânsito é imprescindível que ao utilizar essa função a sua localização seja rastreada ate que as atividades requeridas sejam finalizadas, dessa forma o aplicativo garante que o caminho foi completado de forma integra.
                    </SC.TextP>
                    <br /><SC.Text3>Políticas de privacidade</SC.Text3>
                    <SC.TextP>
                        &emsp;&emsp;&emsp;Na MOSARO SOLUÇÕES LTDA, privacidade e segurança são prioridades e nos comprometemos com a transparência do tratamento de dados pessoais dos nossos usuários/clientes. Por isso, está presente Política de Privacidade estabelece como é feita a coleta, uso e transferência de informações de clientes ou outras pessoas que acessam ou usam nosso site ou aplicativos.

                        <br />&emsp;&emsp;&emsp;Ao utilizar nossos serviços, você entende que coletamos e usamos suas informações pessoais nas formas descritas nesta Política, sob as normas da Constituição Federal de 1988 (art. 5º, LXXIX; e o art. 22º, XXX – incluídos pela EC 115/2022), das normas de Proteção de Dados (LGPD, Lei Federal 13.709/2018), das disposições consumeristas da Lei Federal 8078/1990 e as demais normas do ordenamento jurídico brasileiro aplicáveis.

                        <br />&emsp;&emsp;&emsp;Dessa forma, a MOSARO SOLUÇÕES LTDA, inscrita no CNPJ/MF sob o nº 12.847.012/0001-35 no papel de Controladora de Dados, obriga-se ao disposto na presente Política de Privacidade.

                        <br />&emsp;&emsp;&emsp;É a partir do seu consentimento que tratamos os seus dados pessoais. O consentimento é a manifestação livre, informada e inequívoca pela qual você autoriza a MOSARO SOLUÇÕES LTDA a tratar seus dados.

                        <br />&emsp;&emsp;&emsp;Assim, em consonância com a Lei Geral de Proteção de Dados, seus dados só serão coletados, tratados e armazenados mediante prévio e expresso consentimento.

                        <br />&emsp;&emsp;&emsp;A Mosaro Soluções Ltda assegura a seus usuários/clientes seus direitos de titular previstos no artigo 18 da Lei Geral de Proteção de Dados. Dessa forma, você pode, de maneira gratuita e a qualquer tempo:

                        <br /> <br />&emsp;&emsp;&emsp;
                        <Icons.DoneIcon />
                        Confirmar a existência de tratamento de dados, de maneira simplificada ou em formato claro e completo.

                        <br />&emsp;&emsp;&emsp;
                        <Icons.DoneIcon />
                        Acessar seus dados, podendo solicitá-los em uma cópia legível sob forma impressa ou por meio eletrônico, seguro e idôneo.

                        <br />&emsp;&emsp;&emsp;
                        <Icons.DoneIcon />
                        Corrigir seus dados, ao solicitar a edição, correção ou atualização destes.

                        <br />&emsp;&emsp;&emsp;
                        <Icons.DoneIcon />
                        Limitar seus dados quando desnecessários, excessivos ou tratados em desconformidade com a legislação através da anonimização, bloqueio ou eliminação.

                        <br />&emsp;&emsp;&emsp;
                        <Icons.DoneIcon />
                        Solicitar a portabilidade de seus dados, através de um relatório de dados cadastrais que a MOSARO SOLUÇÕES LTDA trata a seu respeito.

                        <br />&emsp;&emsp;&emsp;
                        <Icons.DoneIcon />
                        Eliminar seus dados tratados a partir de seu consentimento, exceto nos casos previstos em lei.

                        <br />&emsp;&emsp;&emsp;
                        <Icons.DoneIcon />
                        Revogar seu consentimento, desautorizando o tratamento de seus dados.

                        <br />&emsp;&emsp;&emsp;
                        <Icons.DoneIcon />
                        Informar-se sobre a possibilidade de não fornecer seu consentimento e sobre as consequências da negativa.

                    </SC.TextP>
                    <br /><SC.Text3>Por quanto tempo e onde seus dados serão armazenados? </SC.Text3>
                    <SC.TextP>
                        &emsp;&emsp;&emsp;Seus dados pessoais coletados pelos sistemas da MOSARO SOLUÇÕES LTDA, serão utilizados e ficarão armazenados no maior e mais seguro data center do mundo, a Amazon Web Service AWS. Manteremos seus Dados Pessoais enquanto durar o relacionamento entre Você e a MOSARO SOLUÇÕES LTDA, e pelo tempo necessário para fins comerciais específicos para os quais foram coletados. Garantiremos que seus dados sejam usados apenas para cumprir com as obrigações de retenção e não para outros fins.

                        <br />&emsp;&emsp;&emsp;Todos os nossos serviços dispõem de backup, o que significa que os serviços não precisam ser interrompidos, ou seja, a cada dado digitado é feito um backup em tempo real.

                        <br />&emsp;&emsp;&emsp;Findado o período de armazenamento dos dados pessoais, estes serão excluídos de nossas bases de dados ou anonimizados, ressalvadas as hipóteses legalmente previstas no artigo 16 lei geral de proteção de dados, a saber:

                        <br /><br />&emsp;&emsp;&emsp;I – cumprimento de obrigação legal ou regulatória pelo controlador;

                        <br />&emsp;&emsp;&emsp;II – estudo por órgão de pesquisa, garantida, sempre que possível, a anonimização dos dados pessoais;

                        <br />&emsp;&emsp;&emsp;III – transferência a terceiro, desde que respeitados os requisitos de tratamento de dados dispostos nesta Lei; ou

                        <br />&emsp;&emsp;&emsp;IV – uso exclusivo do controlador, vedado seu acesso por terceiro, e desde que anonimizados os dados.

                        <br /><br />&emsp;&emsp;&emsp;Isto é, informações pessoais sobre você que sejam imprescindíveis para o cumprimento de determinações legais, judiciais e administrativas e/ou para o exercício do direito de defesa em processos judiciais e administrativos serão mantidas, a despeito da exclusão dos demais dados.

                        <br />&emsp;&emsp;&emsp;O armazenamento de dados coletados pela MOSARO SOLUÇÕES LTDA reflete o nosso compromisso com a segurança e privacidade dos seus dados. Empregamos medidas e soluções técnicas de proteção aptas a garantir a confidencialidade, integridade e inviolabilidade dos seus dados. Além disso, também contamos com medidas de segurança apropriadas aos riscos e com controle de acesso às informações armazenadas.

                    </SC.TextP>
                    <br /><SC.Text3>Dados Seguros</SC.Text3>
                    <SC.TextP>
                        &emsp;&emsp;&emsp;Para mantermos suas informações pessoais seguras, usamos ferramentas físicas, eletrônicas e gerenciais orientadas para a proteção da sua privacidade.

                        <br />&emsp;&emsp;&emsp;Aplicamos essas ferramentas levando em consideração a natureza dos dados pessoais coletados, o contexto e a finalidade do tratamento e os riscos que eventuais violações gerariam para os direitos e liberdades do titular dos dados coletados e tratados.

                        <br />&emsp;&emsp;&emsp;Entre as medidas que adotamos, destacamos as seguintes:

                        <br />&emsp;&emsp;&emsp;<Icons.DoneIcon />Apenas pessoas autorizadas têm acesso a seus dados pessoais;
                        <br />&emsp;&emsp;&emsp;<Icons.DoneIcon />O acesso a seus dados pessoais é feito somente após o compromisso de confidencialidade;
                        <br />&emsp;&emsp;&emsp;<Icons.DoneIcon />Seus dados pessoais são armazenados em ambiente seguro e idôneo;

                        <br /><br />&emsp;&emsp;&emsp;A MOSARO se compromete a adotar as melhores posturas para evitar incidentes de segurança. Contudo, é necessário destacar que nenhuma página virtual é inteiramente segura e livre de riscos. É possível que, apesar de todos os nossos protocolos de segurança, problemas de culpa exclusivamente de terceiros ocorram, como ataques cibernéticos de hackers, ou também em decorrência da negligência ou imprudência do próprio usuário/cliente.

                        <br />&emsp;&emsp;&emsp;Em caso de incidentes de segurança que possam gerar risco ou dano relevante para você ou qualquer um de nossos usuários/clientes, comunicaremos aos afetados e a Autoridade Nacional de Proteção de Dados sobre o ocorrido, em consonância com as disposições da Lei Geral de Proteção de Dados.

                    </SC.TextP>
                    <br /><SC.Text3>Cookies ou dados de navegação</SC.Text3>
                    <SC.TextP>
                        &emsp;&emsp;&emsp;A MOSARO SOLUÇÕES LTDA faz uso de Cookies, que são arquivos de texto enviados pela plataforma ao seu computador e que nele se armazenam, que contém informações relacionadas à navegação do site. Em suma, os Cookies são utilizados para aprimorar a experiência de uso.

                        <br />&emsp;&emsp;&emsp;Ao acessar nosso site e consentir com o uso de Cookies, você manifesta conhecer e aceitar a utilização de um sistema de coleta de dados de navegação com o uso de Cookies em seu dispositivo.
                    </SC.TextP>
                </SC.ListHolder>
            </SC.ListWhite>
        </>
    );
};
