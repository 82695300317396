import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 30px;

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) { 
    flex-direction: row;
  }
  // X-Large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) { 
    flex-direction: row;
  }
  // XX-Large devices (larger desktops, 1400px and up)
  @media (min-width: 1400px) { 
    flex-direction: row;
  }
`;
export const ImageContent = styled.div`
  display: flex;
  flex: 1;
  position: relative;
  align-items: center;
  justify-content: center;

  & .photo {
    height: 120vw;
    transform: rotate(-13deg);
    z-index: 2;
  }
  & .circle{
    height: 36vw;
    position: absolute;
    margin-left: -240px;
    bottom: 120px;
    z-index: 1;
  }
  & .losangle {
    height: 36vw;
    position: absolute;
    margin-right: -220px;
    bottom: 40px;
    margin-bottom: -40px;
    z-index: 1;
  }

  @media (min-width: 420px) {
    & .photo {
      height: 120vw;
    }
    & .circle{
      height: 40vw;
      margin-left: -300px;
      bottom: 120px;
    }
    & .losangle {
      height: 40vw;
      margin-right: -300px;
      bottom: 20px;
      margin-bottom: -40px;
    }
  }
  @media (min-width: 620px) {
    & .photo {
      height: 100vw;
    }
    & .circle{
      height: 36vw;
      margin-left: -300px;
      bottom: 120px;
    }
    & .losangle {
      height: 36vw;
      margin-right: -400px;
      bottom: 20px;
      margin-bottom: -40px;
    }
  }
  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) { 
    flex: 70%;

    & .photo {
      height: 670px;
    }
    & .circle{
      height: auto;
      margin-left: -300px;
      bottom: 120px;
    }
    & .losangle {
      height: auto;
      margin-right: -400px;
      bottom: 20px;
      margin-bottom: -40px;
    }
  }
  // X-Large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) { 
    flex: 70%;

    & .photo {
      height: 670px;
    }
    & .circle{
      height: auto;
      margin-left: -300px;
      bottom: 120px;
    }
    & .losangle {
      height: auto;
      margin-right: -400px;
      bottom: 20px;
      margin-bottom: -40px;
    }
  }
  // XX-Large devices (larger desktops, 1400px and up)
  @media (min-width: 1400px) { 
    flex: 70%;

    & .photo {
      height: 670px;
    }
    & .circle{
      height: auto;
      margin-left: -300px;
      bottom: 120px;
    }
    & .losangle {
      height: auto;
      margin-right: -400px;
      bottom: 20px;
      margin-bottom: -40px;
    }
  }
`;
export const ChildrenContent = styled.div`
  display: flex;
  flex: 1;
  padding-top: 60px;

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) { 
    flex: 30%;
    padding-top: 140px;
  }
  // X-Large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) { 
    flex: 30%;
    padding-top: 140px;
  }
  // XX-Large devices (larger desktops, 1400px and up)
  @media (min-width: 1400px) { 
    flex: 30%;
    padding-top: 140px;
  }
`;
