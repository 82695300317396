import { Container, ContainerTextArea, Label } from "./styles";

import React from "react";
import { isNumber } from "../../utils/numbers";

export const TextArea = ({
    label,
    name,
    value,
    handleFunction,
    onValueChange,
}) => {
    const handleChange = React.useCallback(
        (event) => {
            const target = event.target;

            if (handleFunction) {
                handleFunction({ ...event, target });
            }

            if (onValueChange) {
                onValueChange({
                    name: target.name,
                    value: target.value,
                    numberValue: isNumber(target.value)
                        ? Number(target.value, 10)
                        : 0,
                });
            }
        },
        [handleFunction, onValueChange]
    );

    return (
        <Container>
            {/* <Label>{label}</Label> */}
            <ContainerTextArea>
                <textarea
                    placeholder={label}
                    className="form-control"
                    name={name}
                    onChange={handleChange}
                    value={value}
                />
            </ContainerTextArea>
        </Container>
    );
};
