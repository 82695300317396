import styled from 'styled-components';

export const Container = styled.div`
  color: white;
  grid-area: 5 / 1 / span 2 / span 1;
  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) { 
    grid-area: 3 / 1 / span 2 / span 2;
  }
  // X-Large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) { 
    grid-area: 3 / 1 / span 2 / span 2;
  }
  // XX-Large devices (larger desktops, 1400px and up)
  @media (min-width: 1400px) { 
    grid-area: 3 / 1 / span 2 / span 2;
  }
`;

export const Label = styled.label`
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  color: #5E5E5E;
  font-weight: normal;
  display: inline-block;
  margin-top: 5px;
  margin-bottom: 5px;
`;

export const ContainerTextArea = styled.div`
  margin-bottom: unset !important;
  background-color: #474960;
  border-radius: 14px;
  margin-top: unset !important;
  display: flex !important;
  align-items: center;
  height: 100%;

  & textarea {
    padding: 12px 16px;
    font-family: 'Poppins' !important;
    font-size: 14px;
    margin-bottom: unset !important;
    color: #ffffff;
    background-color: transparent;
    width: 100%;
    border: none;
    height: 100%;
    resize: none;
    border-radius: 14px;
    margin: 0;
  }
  & textarea:disabled {
    cursor: not-allowed !important;
  }
  & textarea::-webkit-input-placeholder {
    color: #ffffff;
  }
  & textarea:-moz-placeholder {
    /* FF 4-18 */
    color: #ffffff;
    opacity: 1;
  }
  & textarea::-moz-placeholder {
    /* FF 19+ */
    color: #ffffff;
    opacity: 1;
  }
  & textarea:-ms-input-placeholder {
    /* IE 10+ */
    color: #ffffff;
  }
  & textarea::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #ffffff;
  }
  & textarea::placeholder {
    /* modern browser */
    color: #ffffff;
  }
  & textarea:focus {
    color: #ffffff;
    background-color: #474960;
    outline: none;
    border: none;
    border-radius: 14px;
  }
`;