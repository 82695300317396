import useRequest from "../helpers/http/useRequest";

export async function entrarEmContatoServico({
    nome,
    telefone,
    email,
    motivo,
    assunto,
    municipio,
}) {
    return useRequest.post(`/email-open/entrar/contato`, {
        nome,
        telefone,
        email,
        motivo,
        assunto,
        municipio,
    });
}