import styled from "styled-components";

export const Container = styled.div `
    ${({ absolute, fixed }) =>
        absolute && !fixed ? "position: absolute" : ""};
    ${({ fixed, absolute }) => (fixed && !absolute ? "position: fixed" : "")};
    background-color: ${({ background }) => background || "transparent"};
    opacity: ${({ opacity }) => opacity || "0.5"};

    display: flex;

    z-index: 13;

    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
`;

export const Loader = styled.div `
    width: ${({ size }) =>
        size
            ? `
$ { size }
px `
            : "22px"};
    height: ${({ size }) =>
        size
            ? `
$ { size }
px `
            : "22px"};

    border: 3px solid ${({ color }) => color || "#f3f3f3"};

    border-radius: 50%;
    border-top: 3px solid transparent;

    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;

    @-webkit-keyframes spin {
        0% {
            -webkit-transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
        }
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;