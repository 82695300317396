import { MdCancel, MdCheckCircle, MdError, MdWarning } from "react-icons/md";

import { ContainerToast } from "./styles";
import React from "react";
import { toast } from "react-toastify";

const Toast = ({ type, message }) => {
    switch (type) {
        case "warn":
            return toast.warning(
                <ContainerToast>
                    <MdWarning size={24} />
                    <span>{message}</span>
                </ContainerToast>
            );
        case "error":
            return toast.error(
                <ContainerToast>
                    <MdCancel size={24} /> <span>{message}</span>
                </ContainerToast>
            );
        case "success":
            return toast.success(
                <ContainerToast>
                    <MdCheckCircle size={24} /> <span>{message}</span>
                </ContainerToast>
            );
        case "info":
            return toast.info(
                <ContainerToast>
                    <MdError size={24} /> <span>{message}</span>
                </ContainerToast>
            );
        case "dark":
            return toast.dark(
                <ContainerToast>
                    <MdError size={24} /> <span>{message}</span>
                </ContainerToast>
            );
        default:
            return toast(message);
    }
};
export default Toast;
