import React from "react";
import * as SC from "./styles";
import Logo from "./../../images/mosarologo.svg";
import { PiChatCircleText } from "react-icons/pi";

export const Navbar = () => {
  return (
    <SC.Container>
      <SC.LogoContainer href="/">
        <img src={Logo}/>
      </SC.LogoContainer>
      <SC.SmallButton href="/#contato" className="small">
        <PiChatCircleText />
      </SC.SmallButton>
      <SC.Wrapper>
        <SC.NavLinks href="/#servicos">
          Serviços
        </SC.NavLinks>
        <SC.NavLinks href="/#sobre">
          Sobre
        </SC.NavLinks>
        <SC.NavLinks href="/#produtos">
          Produtos
        </SC.NavLinks>
        <SC.HeaderButton href="/#contato">
          Entre em contato
        </SC.HeaderButton>
      </SC.Wrapper>
    </SC.Container>

    // <NavbarBootstrap bg="light" expand="lg" className="sticky-top">
    //   <Container>
    //     <SC.LogoText href="/" className="Logo">
    //       <img src={Logo}/>
    //     </SC.LogoText>

    //     <NavbarBootstrap.Toggle aria-controls="basic-navbar-nav" />

    //     <NavbarBootstrap.Collapse
    //       id="basic-navbar-nav"
    //       className="justify-content-end"
    //     >
    //       <Nav>
    //         <SC.LinkNav href="/" className="">
    //           {" "}
    //           home
    //         </SC.LinkNav>
    //         <SC.LinkNav href="/produtos"> produtos</SC.LinkNav>
    //         <SC.LinkNav href="/#contato"> contato</SC.LinkNav>
    //         <SC.LinkNav href="/sobre"> sobre</SC.LinkNav>
    //       </Nav>
    //     </NavbarBootstrap.Collapse>
    //   </Container>
    // </NavbarBootstrap>
  );
};
