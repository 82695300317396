import styled from 'styled-components';

export const Container = styled.div`
  color: white;
`;

export const Label = styled.label`
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  color: #5E5E5E;
  font-weight: normal;
  display: inline-block;
  margin-top: 5px;
  margin-bottom: 5px;
`;

export const ContainerInput = styled.div`
  margin-bottom: unset !important;
  background-color: #474960;
  border-radius: 14px;
  margin-top: unset !important;
  display: flex !important;
  height: 50px;
  align-items: center;

  & input {
    height: 100%;
    border-radius: 14px;
    padding: 0 16px;
    font-family: 'Poppins' !important;
    font-size: 14px;
    margin-bottom: unset !important;
    color:  #ffffff;
    background-color: transparent;
    width: 100%;
    border: none;
  }

  & input:disabled {
    cursor: not-allowed !important;
  }
  & input::-webkit-input-placeholder {
    color: #ffffff;
  }
  & input:-moz-placeholder {
    /* FF 4-18 */
    color: #ffffff;
    opacity: 1;
  }
  & input::-moz-placeholder {
    /* FF 19+ */
    color: #ffffff;
    opacity: 1;
  }
  & input:-ms-input-placeholder {
    /* IE 10+ */
    color: #ffffff;
  }
  & input::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #ffffff;
  }
  & input::placeholder {
    /* modern browser */
    color: #ffffff;
  }
  & input:focus {
    outline: none;
    border-color: #80bdff;
    box-shadow: 0 0 0 .2rem rgba(0,123,255,.25);
  }
  & input[type='text'] {

  }
  & input[type='text']:focus {
    border-color: #80bdff;
    box-shadow: 0 0 0 .2rem rgba(0,123,255,.25);
  }
  & input::-webkit-calendar-picker-indicator {
    filter: invert(1);
  }
`;