import React from 'react'
import * as SC from './styles';
import Logo from "./../../images/mosarologo.svg";

export default function Footer() {
  return (
    <SC.FooterContent>
      <SC.TextInfo>
        Todos os direitos reservados, ®2024
      </SC.TextInfo>
      <SC.LogoContainer href="/">
        <img src={Logo}/>
      </SC.LogoContainer>
      <SC.NavLinks href="/politica-privacidade">
        Politica de privacidade
      </SC.NavLinks>
    </SC.FooterContent>
    // <>
    //   <SC.ColorDiv>
    //     <SC.FooterContainer>
    //       <SC.FooterDiv>
    //         <SC.LogoText href="/" className="Logo">
    //         <img src={LogoSlogan}/>
    //         </SC.LogoText>
    //         <SC.IconsDiv>
    //           <div><SC.FacebookIcon /></div>
    //           <div><SC.InIcon /></div>
    //           <a href="https://instagram.com/mosaro.dev">
    //             <div><SC.InstagramIcon /></div>
    //           </a>
    //         </SC.IconsDiv>
    //       </SC.FooterDiv>
    //       <SC.EndGridItem>
    //         <SC.Text5>Precisa falar conosco?</SC.Text5>
    //         <SC.Text6>Maceió, Ponta Verde - Alagoas</SC.Text6>
    //         {/*<SC.Text6>(82) 99999-9999, (82) 98888-8888</SC.Text6>*/}
    //         <SC.Text6>contato@mosaro.com.br</SC.Text6>
    //       </SC.EndGridItem>
    //       <SC.EndGridItem>
    //         <SC.Text5>Serviços</SC.Text5>
    //         <a href="/produtos">
    //         <SC.Text6>Tecnologias</SC.Text6>
    //         </a>
    //         <a href="/produtos">
    //         <SC.Text6>Sistema</SC.Text6>
    //         </a>
    //         <a href="/produtos">
    //         <SC.Text6>Soluções Luminosas</SC.Text6>
    //         </a>
    //       </SC.EndGridItem>
    //       <SC.EndGridItem>
    //         <SC.Text5>Sobre</SC.Text5>
    //         <a href="/sobre">
    //         <SC.Text6>Sobre nós</SC.Text6>
    //         </a>
    //         <a href="/#contato">
    //         <SC.Text6>Entre em contato</SC.Text6>
    //         </a>
    //         <a href="/politica-privacidade">
    //         <SC.Text6>Políticas de privacidade</SC.Text6>
    //         </a>
    //       </SC.EndGridItem>
    //     </SC.FooterContainer>
    //   </SC.ColorDiv>
    //   <SC.EndFooter>
    //     <SC.LogoFooter href="/" className="Logo"><img src={Logo}/></SC.LogoFooter>
    //   </SC.EndFooter>
    // </>
  )
}