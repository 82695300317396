import styled from 'styled-components';

export const Container = styled.header`
  height: 54px;
  background-color: #3A3C53;
  padding:  14px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;

  & .small {
    & svg {
      color: #222222;
      height: 20px;
      width: 20px;
    }
  }

  @media (min-width: 420px) {
    padding:  14px 20px;
  }
  @media (min-width: 620px) {
    height: 80px;
    padding: 20px;
  }
  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) { 
    flex-direction: row;
    padding: 20px 8%;

    & .small {
      display: none;
    }
  }
  // X-Large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) { 
    flex-direction: row;
    padding: 20px 10%;

    & .small {
      display: none;
    }
  }
  // XX-Large devices (larger desktops, 1400px and up)
  @media (min-width: 1400px) { 
    flex-direction: row;
    padding: 20px 12%;

    & .small {
      display: none;
    }
  }
`;
export const LogoContainer = styled.a`
  height: 26px;
  width: 160px;

  & img {
    height: 26px;
  }

  @media (min-width: 420px) {
    height: 26px;

    & img {
    height: 26px;
  }
  }
  @media (min-width: 620px) {
    height: 34px;

    & img {
      height: 34px;
    }
  }
`;
export const HeaderButton = styled.a`
  height: 40px;
  padding: 10px 20px;
  border-radius: 20px;
  background-color: #F9BD36;
  border: none;
  font-family: 'Poppins';
  font-weight: bold;
  color: #222222;
  font-size: 14px;
  margin-left: 24px;

  &:hover {
    opacity: 0.8;
    color: #222222;
    text-decoration: none;
  }
`;
export const SmallButton = styled.a`
  height: 30px;
  border-radius: 20px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F9BD36;
  border: none;

  &:hover {
    opacity: 0.8;
    color: #222222;
    text-decoration: none;
  }
`;
export const Wrapper = styled.div`
  display: none;
  align-items: center;
  gap: 36px;

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) { 
    display: flex;
  }
  // X-Large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) { 
    display: flex;
  }
  // XX-Large devices (larger desktops, 1400px and up)
  @media (min-width: 1400px) { 
    display: flex;
  }
`;
export const NavLinks = styled.a`
  font-family: 'Poppins';
  font-weight: bold;
  color: #FFFFFF;
  font-size: 16px;
  cursor: pointer;
  
  &:hover {
    opacity: 0.8;
    color: #FFFFFF;
    text-decoration: none;
  }
`;
// export const LogoText = styled(Navbar.Brand)`
//   & img{
//     height: 35px;
//     width: 100%;
//   }
//   // Large devices (desktops, 992px and up)
//   @media (min-width: 992px) { 
//     height: 46px;
//    }
//   // X-Large devices (large desktops, 1200px and up)
//   @media (min-width: 1200px) { 
//     height: 46px;
//    }
//   // XX-Large devices (larger desktops, 1400px and up)
//   @media (min-width: 1400px) { 
//     height: 46px;
//    }
// `;

// export const LinkNav = styled(Nav.Link)`
//   font-family: 'Poppins';
//   font-weight: bold;
//   color: #222222;
//   font-size: 14px;
//   text-transform: uppercase;
// `;