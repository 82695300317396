import styled from 'styled-components';

export const FooterContent = styled.footer`
  height: auto;
  width: 100%;
  background-color: #474960;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px 20px;
  gap: 20px;
  flex-direction: column;
  
  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) { 
    padding: 60px 8%;
    flex-direction: row;
    height: 140px;
  }
  // X-Large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) { 
    padding: 60px 10%;
    flex-direction: row;
    height: 140px;
  }
  // XX-Large devices (larger desktops, 1400px and up)
  @media (min-width: 1400px) { 
    padding: 60px 12%;
    flex-direction: row;
    height: 140px;
  }
`;
export const LogoContainer = styled.div`
  height: 34px;
  width: 160px;
  order: -1;
  margin-bottom: 20px;

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) { 
    order: 0;
    margin-bottom: 0;
  }
  // X-Large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) { 
    order: 0;
    margin-bottom: 0;
  }
  // XX-Large devices (larger desktops, 1400px and up)
  @media (min-width: 1400px) { 
    order: 0;
    margin-bottom: 0;
  }
`;
export const TextInfo = styled.p`
  font-family: 'Poppins';
  font-weight: normal;
  color: #FFFFFF;
  font-size: 14px;
  cursor: pointer;
  margin: 0;
`;
export const NavLinks = styled.a`
  font-family: 'Poppins';
  font-weight: normal;
  color: #FFFFFF;
  font-size: 14px;
  cursor: pointer;
  
  &:hover {
    opacity: 0.8;
    color: #FFFFFF;
    text-decoration: none;
  }
`;
// export const FacebookIcon = styled(FaFacebookF)`
//   color: #7764E4 ;
//   font-size: 16px !important;
//   // Large devices (desktops, 992px and up)
//   @media (min-width: 992px) { 
//   font-size: 20px !important;
//    }
//   // X-Large devices (large desktops, 1200px and up)
//   @media (min-width: 1200px) { 
//   font-size: 20px !important;
//    }
//   // XX-Large devices (larger desktops, 1400px and up)
//   @media (min-width: 1400px) { 
//   font-size: 20px !important;
//    }
// `;
// export const InIcon = styled(FaLinkedinIn)`
//   color: #7764E4 ;
//   font-size: 16px !important;
//   // Large devices (desktops, 992px and up)
//   @media (min-width: 992px) { 
//   font-size: 20px !important;
//    }
//   // X-Large devices (large desktops, 1200px and up)
//   @media (min-width: 1200px) { 
//   font-size: 20px !important;
//    }
//   // XX-Large devices (larger desktops, 1400px and up)
//   @media (min-width: 1400px) { 
//   font-size: 20px !important;
//    }
// `;
// export const InstagramIcon = styled(FaInstagram)`
//   color: #7764E4 ;
//   font-size: 16px !important;
//   // Large devices (desktops, 992px and up)
//   @media (min-width: 992px) { 
//   font-size: 20px !important;
//    }
//   // X-Large devices (large desktops, 1200px and up)
//   @media (min-width: 1200px) { 
//   font-size: 20px !important;
//    }
//   // XX-Large devices (larger desktops, 1400px and up)
//   @media (min-width: 1400px) { 
//   font-size: 20px !important;
//    }
// `;

// export const ColorDiv = styled.div`
//   background-color: #eeeeee;
// `;

// export const FooterContainer = styled(Container)`
//   display: grid;
//   grid-template-columns: 1fr;
//   justify-items: stretch;
//   grid-column-gap: 40px;
//   grid-row-gap: 30px;
//   padding-top: 60px;
//   padding-bottom: 100px;
//   // Large devices (desktops, 992px and up)
//   @media (min-width: 992px) { 
//     grid-template-columns: 1fr 1fr 1fr;
//     grid-row-gap: 70px;
//    }
//   // X-Large devices (large desktops, 1200px and up)
//   @media (min-width: 1200px) { 
//     grid-template-columns: 1fr 1fr 1fr;
//     grid-row-gap: 70px;
//    }
//   // XX-Large devices (larger desktops, 1400px and up)
//   @media (min-width: 1400px) { 
//     grid-template-columns: 1fr 1fr 1fr;
//     grid-row-gap: 70px;
//    }
// `;

// export const FooterDiv = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   border-bottom-color: #7764E4;
//   border-bottom-style: solid;
//   border-bottom-width: 2px;
//   padding-bottom: 30px;
//     // Large devices (desktops, 992px and up)
//     @media (min-width: 992px) { 
//   grid-area: 1 / 1 / span 1 / span 3;
//    }
//   // X-Large devices (large desktops, 1200px and up)
//   @media (min-width: 1200px) { 
//   grid-area: 1 / 1 / span 1 / span 3;
//    }
//   // XX-Large devices (larger desktops, 1400px and up)
//   @media (min-width: 1400px) { 
//   grid-area: 1 / 1 / span 1 / span 3;
//    }
// `;

// export const LogoText = styled(Navbar.Brand)`
// & img{
//   height: 50px;
//   width: 100%;
// }
//   // Large devices (desktops, 992px and up)
//   @media (min-width: 992px) { 
//     height: 60px;
//    }
//   // X-Large devices (large desktops, 1200px and up)
//   @media (min-width: 1200px) { 
//     height: 60px;
//    }
//   // XX-Large devices (larger desktops, 1400px and up)
//   @media (min-width: 1400px) { 
//     height: 60px;
//    }
// `;

// export const IconsDiv = styled.div`
//   display: flex;
//   align-items: center;
//   & div {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     border-radius: 100%;
//     background-color: transparent;
//     border: 2px solid #7764E4;
//     height: 4vh;
//     width: 4vh;
//     // Large devices (desktops, 992px and up)
//     @media (min-width: 992px) { 
//     height: 6vh;
//     width: 6vh;
//      }
//     // X-Large devices (large desktops, 1200px and up)
//     @media (min-width: 1200px) { 
//     height: 6vh;
//     width: 6vh;
//     }
//     // XX-Large devices (larger desktops, 1400px and up)
//     @media (min-width: 1400px) { 
//     height: 6vh;
//     width: 6vh;
//     }
//   }
//   & div:nth-child(even) {
//     margin-left: 10px;
//     margin-right: 10px;
//   }
// `;

// export const Text5 = styled.h5`
//   font-family: 'Montserrat', sans-serif;
//   font-weight: bold;
//   color: #5E5E5E;
//   font-size: 12px !important;
//   // Large devices (desktops, 992px and up)
//   @media (min-width: 992px) { 
//   font-size: 16px !important;
//    }
//   // X-Large devices (large desktops, 1200px and up)
//   @media (min-width: 1200px) { 
//   font-size: 16px !important;
//    }
//   // XX-Large devices (larger desktops, 1400px and up)
//   @media (min-width: 1400px) { 
//   font-size: 16px !important;
//    }
// `;
// export const Text6 = styled.h6`
//   font-family: 'Montserrat', sans-serif;
//   font-weight: normal;
//   color: #5E5E5E;
//   font-size: 12px !important;
//   // Large devices (desktops, 992px and up)
//   @media (min-width: 992px) { 
//   font-size: 16px !important;
//    }
//   // X-Large devices (large desktops, 1200px and up)
//   @media (min-width: 1200px) { 
//   font-size: 16px !important;
//    }
//   // XX-Large devices (larger desktops, 1400px and up)
//   @media (min-width: 1400px) { 
//   font-size: 16px !important;
//    }
// `;

// export const EndGridItem = styled.div`
//   display: flex;
//   align-items: flex-start;
//   flex-direction: column;
// `;

// export const EndFooter = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   background-color: #7764E4;
//   height: 12vh;
// `;

// export const LogoFooter = styled(Navbar.Brand)`
// & img{
//   height: 50px;
//   width: 100%;
// }
//   // Large devices (desktops, 992px and up)
//   @media (min-width: 992px) { 
//     height: 50px;
//    }
//   // X-Large devices (large desktops, 1200px and up)
//   @media (min-width: 1200px) { 
//     height: 50px;
//    }
//   // XX-Large devices (larger desktops, 1400px and up)
//   @media (min-width: 1400px) { 
//     height: 50px;
//    }
// `;