import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Navbar } from "../components/Navbar";
import { Home } from '../pages/Home';
// import { Produtos } from '../pages/Produtos';
// import { Sobre } from '../pages/Sobre';
import { PoliticaPrivacidade } from '../pages/PoliticaPrivacidade';
import { PoliticaOperador } from '../pages/PoliticaOperador';

export default function Router() {
  return (
    <>
      <Navbar />

      <BrowserRouter>
        <Switch>
          <Route path="/" component={Home} exact />
          {/* <Route path="/produtos" component={Produtos} />
          <Route path="/sobre" component={Sobre} /> */}
          <Route path="/politica-privacidade" component={PoliticaPrivacidade} />
          <Route path="/politica-privacidade-operador" component={PoliticaOperador} />
        </Switch>
      </BrowserRouter>
    </>
  );
}