import React from 'react'
import * as SC from './styles';


export default function PhoneCard({ src, label }) {
  return (
    <SC.Wrapper>
      <SC.Filler />
      <SC.Container>
        <SC.ImageContent>
          <img src={src} className='photo'/>
        </SC.ImageContent>
        <SC.Text>
          {label}
        </SC.Text>
      </SC.Container>
    </SC.Wrapper>
  )
}