import { Container, ContainerInput, Label } from "./styles";

import React from "react";
import { isNumber } from "../../utils/numbers";

export const Input = ({
    type,
    label,
    name,
    handleFunction,
    onValueChange,
    ...otherProps
}) => {
    const handleChange = React.useCallback(
        (event) => {
            const target = event.target;

            if (handleFunction) {
                handleFunction({ ...event, target });
            }

            if (onValueChange) {
                onValueChange({
                    name: target.name,
                    value: target.value,
                    numberValue: isNumber(target.value)
                        ? Number(target.value)
                        : 0,
                });
            }
        },
        [handleFunction, onValueChange]
    );

    return (
        <Container>
            {/* {label && <Label>{label}</Label>} */}
            <ContainerInput>
                <input
                    placeholder={label}
                    type={type || "text"}
                    name={name}
                    onChange={handleChange}
                    {...otherProps}
                />
            </ContainerInput>
        </Container>
    );
};
