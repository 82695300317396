import styled from 'styled-components';

export const Container = styled.div`
  color: white;
`;

export const Label = styled.label`
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  color: #5E5E5E;
  font-weight: normal;
  display: inline-block;
  margin-top: 5px;
  margin-bottom: 5px;
`;

export const ContainerSelect = styled.div`
  margin-bottom: unset !important;
  background-color: white;
  color: #5E5E5E;
  border-radius: 10px;
  margin-top: unset !important;
  display: flex !important;
  height: 50px;
  font-size: 12px;
  align-items: center;
  border: 2px solid #7764E4;

  & select {
    background-color: transparent;
    color: #5E5E5E;
    width: 100%;
    padding: 5px;
    border: none;
  }
  & select:focus {
    outline: none;
  }
  & select option {
    color: #5E5E5E;
    background-color: white;
  }
`;