import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) { 
    align-items: flex-start;

  }
  // X-Large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) { 
    align-items: flex-start;

  }
  // XX-Large devices (larger desktops, 1400px and up)
  @media (min-width: 1400px) { 
    align-items: flex-start;
  }
`;
export const Title = styled.h1`
  font-family: 'Poppins';
  font-weight: bold;
  color: #231F20;
  font-size: 24px;
  margin-bottom: 16px;
  text-align: right;
  display: flex;
  justify-content: flex-start;

  & b {
    color: #B473B0;
    font-size: 16px;
  }
  & br {
    display: none;
  }

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) { 
    font-size: 40px;
    margin-bottom: 26px;
    text-align: left;

    & br {
      display: block;
    }
  }
  // X-Large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) { 
    font-size: 40px;
    margin-bottom: 26px;
    text-align: left;

    & br {
      display: block;
    }
  }
  // XX-Large devices (larger desktops, 1400px and up)
  @media (min-width: 1400px) { 
    font-size: 40px;
    margin-bottom: 26px;
    text-align: left;

    & br {
      display: block;
    }
  }
`;
export const Subtitle = styled.h2`
  font-family: 'Poppins';
  font-weight: normal;
  color: #231F20;
  font-size: 14px;
  margin-bottom: 4px;
  text-align: right;
  width: 70%;

  & br {
    display: none;
  }

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) { 
    font-size: 16px;
    text-align: left;

    & br {
      display: block;
    }
  }
  // X-Large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) { 
    font-size: 16px;
    text-align: left;

    & br {
      display: block;
    }
  }
  // XX-Large devices (larger desktops, 1400px and up)
  @media (min-width: 1400px) { 
    font-size: 16px;
    text-align: left;

    & br {
      display: block;
    }
  }
`;
export const ClickTitle = styled.button`
  font-family: 'Poppins';
  font-weight: 500;
  color: #B473B0;
  font-size: 14px;
  width: max-content;
  background-color: transparent;
  border: 0;
  padding: 0;
   
  & svg {
    font-size: 18px;
  }
`;