import styled from 'styled-components';

export const Wrapper = styled.div`
  flex: 1;
  width: 100%;
  overflow: hidden;

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) { 
    flex: 33%;
  }
  // X-Large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) { 
    flex: 33%;
  }
  // XX-Large devices (larger desktops, 1400px and up)
  @media (min-width: 1400px) { 
    flex: 33%;
  }
`;
export const Filler = styled.div`
  width: 100%;
  height: 80px;
  background-color: transparent;
`;
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 320px;
  padding: 36px;
  background-color: #474960;
  border-radius: 26px;
`;
export const ImageContent = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;

  & .photo {
    height: 350px;
    margin-top: -130px;
  }
`;
export const Text = styled.h3`
  font-family: 'Poppins';
  font-weight: bold;
  color: #ffffff;
  font-size: 24px;
  text-align: center;
  margin: 0;
`;
