import styled from 'styled-components';
import { Container } from 'react-bootstrap';
import landpic from '../images/landpic-dark.png';
import landpic2 from '../images/landpic6.jpg';
import landpic3 from '../images/landpic-new.jpeg';
import notebook from '../images/notebook.png';
import celular from '../images/celular.png';
import demo from '../images/demo.png';
import devpic from '../images/dev.jpg';
import devpic2 from '../images/dev2.jpg';

export const DarkSection = styled.section`
  display: flex;
  width: 100%;
  padding: 40px 20px 80px 20px;
  background-color: #3A3C53;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) { 
    padding: 120px 8% 150px 8%;
   }
  // X-Large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) { 
    padding: 120px 10% 150px 10%;
   }
  // XX-Large devices (larger desktops, 1400px and up)
  @media (min-width: 1400px) { 
    padding: 120px 12% 150px 12%;
   }
`;
export const ButtonsFloat = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
  align-items: center;
  z-index: 3;
  width: 100%;

  & svg {
    color: #ffffff;
    height: 30px;
    width: 30px;
    order: -3;
  }

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) { 
    margin-left: -80px;
    width: auto;

    & svg {
      order: +3;
    }
  }
  // X-Large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) { 
    margin-left: -100px;
    width: auto;

    & svg {
      order: +3;
    }
  }
  // XX-Large devices (larger desktops, 1400px and up)
  @media (min-width: 1400px) { 
    margin-left: -150px;
    width: auto;

    & svg {
      order: +3;
    }
  }
`;
export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 14px;
  align-self: center;

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) { 
    align-self: flex-start;
  }
  // X-Large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) { 
    align-self: flex-start;
  }
  // XX-Large devices (larger desktops, 1400px and up)
  @media (min-width: 1400px) { 
    align-self: flex-start;
  }
`;
export const ButtonWrapper2 = styled.div`
  display: flex;
  flex-direction: row;
  gap: 14px;
  align-self: center;

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) { 
    margin-left: 12px;
    align-self: flex-start;
  }
  // X-Large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) { 
    margin-left: 12px;
    align-self: flex-start;
  }
  // XX-Large devices (larger desktops, 1400px and up)
  @media (min-width: 1400px) { 
    margin-left: 12px;
    align-self: flex-start;
  }
`;
export const ButtonWrapper3 = styled.div`
  display: flex;
  flex-direction: row;
  gap: 14px;
  align-self: center;

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) { 
    margin-left: 24px;
    align-self: flex-start;
  }
  // X-Large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) { 
    margin-left: 24px;
    align-self: flex-start;
  }
  // XX-Large devices (larger desktops, 1400px and up)
  @media (min-width: 1400px) { 
    margin-left: 24px;
    align-self: flex-start;
  }
`;
export const ButtonSwapper = styled.button`
  height: 30px;
  border-radius: 15px;
  padding: 2px 8px 0 8px;
  border: 2px solid ${props => props.active ? '#F9BD36' : '#FFFFFF'};
  font-family: 'Poppins';
  font-weight: normal;
  color: #ffffff;
  font-size: 12px;
  align-items: center;
  justify-content: center;
  background-color: transparent;

  &:hover {
    opacity: 0.8;
  }

  @media (min-width: 350px) {
    padding: 2px 14px 0 14px;
    font-size: 14px;
  }
`;
export const PurpleStripe = styled.section`
  display: flex;
  width: 100%;
  padding: 36px 20px;
  background-color: #6E68AF;
  display: flex;
  flex-direction: column;
  gap: 36px;

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) { 
    flex-direction: row;
    padding: 36px 8%;
  }
  // X-Large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) { 
    flex-direction: row;
    padding: 36px 10%;
  }
  // XX-Large devices (larger desktops, 1400px and up)
  @media (min-width: 1400px) { 
    flex-direction: row;
    padding: 36px 12%;
  }
`;
export const TextDuo = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;

  & h1 {
    font-family: 'Poppins';
    font-weight: bold;
    color: #ffffff;
    font-size: 36px;
  }
  & h2 {
    font-family: 'Poppins';
    font-weight: normal;
    color: #ffffff;
    font-size: 16px;
  }

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) { 
    & h1 {
      font-size: 40px;
    }
  }
  // X-Large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) { 
    & h1 {
      font-size: 40px;
    }
  }
  // XX-Large devices (larger desktops, 1400px and up)
  @media (min-width: 1400px) { 
    & h1 {
      font-size: 40px;
    }
  }
`;
export const DarkSection2 = styled.section`
  display: flex;
  width: 100%;
  padding: 60px 20px 80px 20px;
  background-color: #3A3C53;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  overflow: hidden;

  & .bigphoto {
    width: calc(100vw - 40px);
    z-index: 1;
  }
  & .backphoto {
    position: absolute;
    width: 100vw;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.2;
    margin: auto;
  }

  @media (min-width: 620px) {
    padding: 140px 20px 100px 20px;
  }
  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) { 
    padding: 140px 8% 100px 8%;

    & .bigphoto {
      width: 600px;
    }
  }
  // X-Large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) { 
    padding: 140px 10% 100px 10%;

    & .bigphoto {
      width: 700px;
    }
  }
  // XX-Large devices (larger desktops, 1400px and up)
  @media (min-width: 1400px) { 
    padding: 140px 12% 100px 12%;

    & .bigphoto {
      width: 700px;
    }
  }
`;
export const WhiteSection = styled.section`
  display: flex;
  width: 100%;
  padding: 100px 20px;
  background-color: #ffffff;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  height: 720px;

  & .art1 {
    position: absolute;
    top: 0;
    left: 0;
    height: 20%;
  }
  & .art2 {
    position: absolute;
    top: 0;
    left: 0;
    height: 20%;
  }
  & .main {
    position: absolute;
    top: 160px;
    bottom: 0;
    right: 0;
    width: 700px;
    margin-right: -100vw;
  }

  @media (min-width: 350px) {
    & .main {
      margin-right: -60vw;
    }
  }

  @media (min-width: 420px) {
    height: 760px;

    & .art1 {
      height: 20%;
    }
    & .art2 {
      height: 20%;
    }
    & .main {
      position: absolute;
      top: 180px;
      bottom: 0;
      right: 0;
      width: 700px;
      margin-right: -40vw;
    }
  }
  @media (min-width: 620px) {
    padding: 120px 20px;
    height: 920px;

    & .art1 {
      height: 30%;
    }
    & .art2 {
      height: 30%;
    }
    & .main {
      width: 1000px;
      margin-right: -40vw;
    }
  }
  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) { 
    height: auto;
    padding: 300px 8%;

    & .main {
      top: 0;
      margin-right: -540px;
      width: 1300px;
    }
    & .art1 {
      height: auto;
    }
    & .art2 {
      height: auto;
    }
  }
  // X-Large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) { 
    height: auto;
    padding: 300px 10%;

    & .main {
      top: 0;
      margin-right: -400px;
      width: 1300px;
    }
    & .art1 {
      height: auto;
    }
    & .art2 {
      height: auto;
    }
  }
  // XX-Large devices (larger desktops, 1400px and up)
  @media (min-width: 1400px) {
    height: auto;
    padding: 300px 12%;

    & .main {
      top: 0;
      margin-right: -364px;
      width: 1300px;
    }
    & .art1 {
      height: auto;
    }
    & .art2 {
      height: auto;
    }
  }
`;
export const Label = styled.label`
  font-family: 'Poppins';
  font-weight: normal;
  color: #909090;
  font-size: 12px;
  text-align: right;
  margin-top: 420px;
  width: 100%;

  @media (min-width: 420px) {
    text-align: right;
    margin-top: 460px;
  }
  @media (min-width: 620px) {
    text-align: right;
    margin-top: 600px;
  }
  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) { 
    text-align: left;
    margin-top: 200px;
  }
  // X-Large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) { 
    text-align: left;
    margin-top: 200px;
  }
  // XX-Large devices (larger desktops, 1400px and up)
  @media (min-width: 1400px) { 
    text-align: left;
    margin-top: 200px;
  }
`;
export const DarkSection3 = styled.section`
  display: flex;
  width: 100%;
  padding: 80px 20px 100px 20px;
  background-color: #3A3C53;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;

  & .art3 {
    position: absolute;
    top: 0;
    right: 0;
    height: 4%;
  }
  & .art4 {
    position: absolute;
    top: 0;
    right: 40px;
    height: 4%;
  }

  @media (min-width: 420px) {
    padding: 120px 20px 120px 20px;
    & .art3 {
      height: 6%;
    }
    & .art4 {
      height: 6%;
    }
  }
  @media (min-width: 620px) {
    padding: 160px 20px 160px 20px;
    & .art3 {
      height: 10%;
    }
    & .art4 {
      height: 10%;
    }
  }
  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) { 
    padding: 220px 8% 180px 8%;
    & .art3 {
      height: 20%;
    }
    & .art4 {
      height: 20%;
    }
  }
  // X-Large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) { 
    padding: 220px 10% 180px 10%;

    & .art3 {
      height: auto;
    }
    & .art4 {
      height: auto;
    }
  }
  // XX-Large devices (larger desktops, 1400px and up)
  @media (min-width: 1400px) { 
    padding: 220px 12% 180px 12%;

    & .art3 {
      height: auto;
    }
    & .art4 {
      height: auto;
    }
  }
`;
export const CardsContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: baseline;
  gap: 40px;
  padding-top: 40px;

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) { 
    padding-top: 60px;
    flex-direction: row;
    gap: 20px;
  }
  // X-Large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) { 
    padding-top: 100px;
    flex-direction: row;
    gap: 20px;
  }
  // XX-Large devices (larger desktops, 1400px and up)
  @media (min-width: 1400px) { 
    padding-top: 100px;
    flex-direction: row;
    gap: 20px;
  }
`;
export const ColorStripe1 = styled.div`
  width: 100%;
  height: 10px;
  background-color: #F9BD36;
`;
export const ColorStripe2 = styled.div`
  width: 100%;
  height: 10px;
  background-color: #EB5D6A;
`;
export const ColorStripe3 = styled.div`
  width: 100%;
  height: 10px;
  background-color: #6E68AF;
`;
export const ColorStripe4 = styled.div`
  width: 100%;
  height: 10px;
  background-color: #B473B0;
`;
export const DarkSection4 = styled.section`
  display: flex;
  width: 100%;
  padding: 60px 20px 100px 20px;
  background-color: #3A3C53;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media (min-width: 420px) {
    padding: 60px 20px 100px 20px;
  }
  @media (min-width: 620px) {
    padding: 60px 20px 100px 20px;
  }
  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) { 
    padding: 120px 8% 140px 8%;
  }
  // X-Large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) { 
    padding: 140px 10% 160px 10%;
  }
  // XX-Large devices (larger desktops, 1400px and up)
  @media (min-width: 1400px) { 
    padding: 140px 12% 160px 12%;
  }
`;
export const ColumnContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 36px;
  padding-top: 60px;
  flex-direction: column;

  @media (min-width: 420px) {
    padding-top: 60px;
  }
  @media (min-width: 620px) {
    padding-top: 90px;
  }
  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) { 
    flex-direction: row;
  }
  // X-Large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) { 
    flex-direction: row;
  }
  // XX-Large devices (larger desktops, 1400px and up)
  @media (min-width: 1400px) { 
    flex-direction: row;
  }
`;
export const SmallColumn = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 46px;

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) { 
    width: calc((100vw - 12% - 18px)*0.3);
  }
  // X-Large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) { 
    width: calc((100vw - 12% - 18px)*0.3);
  }
  // XX-Large devices (larger desktops, 1400px and up)
  @media (min-width: 1400px) { 
    width: calc((100vw - 12% - 18px)*0.3);
  }
`;
export const SmallColumnSection = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 12px;
`;
export const LargeColumn = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;
  flex-direction: column;

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) { 
    width: calc((100vw - 12% - 18px)*0.7);
  }
  // X-Large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) { 
    width: calc((100vw - 12% - 18px)*0.7);
  }
  // XX-Large devices (larger desktops, 1400px and up)
  @media (min-width: 1400px) { 
    width: calc((100vw - 12% - 18px)*0.7);
  }
`;
export const TopicTitle = styled.h3`
  font-family: 'Poppins';
  font-weight: bold;
  color: #ffffff;
  font-size: 18px;
`;
export const IconText = styled.div`
  display: flex;
  width: 100%;
  gap: 6px;
  align-items: center;

  & label {
    font-family: 'Poppins';
    font-weight: normal;
    color: #ffffff;
    font-size: 14px;
    margin: 0;
  }
  & svg {
    width: 24px;
    height: 24px;
    color: #F9BD36;
  }
`;
export const IconRow = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;
  align-items: center;

  & svg {
    width: 24px;
    height: 24px;
    color: #F9BD36;
  }
`;
export const FormRow = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;
`;
export const FormContent = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;
  align-items: flex-end;
  padding-top: 40px;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: #474960;
  justify-content: space-between;
  flex-direction: column;

  & label {
    font-family: 'Poppins';
    font-weight: normal;
    color: #ffffff;
    font-size: 14px;
    margin: 0;
  }
  & button {
    display: flex;
    height: 46px;
    padding: 14px 56px;
    align-items: center;
    justify-content: center;
    border-radius: 23px;
    border: 0;
    background-color: #F9BD36;
    font-family: 'Poppins';
    font-weight: bold;
    color: #231F20;
    font-size: 14px;
    &:hover {
      opacity: 0.8;
    }
  }

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) { 
    align-items: center;
    flex-direction: column;
  }
  // X-Large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) { 
    align-items: center;
    flex-direction: column;
  }
  // XX-Large devices (larger desktops, 1400px and up)
  @media (min-width: 1400px) { 
    align-items: center;
    flex-direction: column;
  }
`;
//old
export const ImageContainer = styled.div`
  display: flex;
  align-items: flex-end;
  background-image: url(${landpic});
  height: 75vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  max-width: 100%;
`;
export const ImageContainer2 = styled.div`
  display: flex;
  align-items: flex-end;
  background-image: url(${landpic2});
  height: 75vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  max-width: 100%;
`;
export const ImageContainer3 = styled.div`
  display: flex;
  align-items: flex-end;
  background-image: url(${landpic3});
  height: 75vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  max-width: 100%;
`;
export const Text1 = styled.h1`
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  color: white;
  filter: opacity(0.3);
  line-height: 0 !important;
  font-size: 40px !important;
  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) { 
  font-size: 80px !important;
   }
  // X-Large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) { 
  font-size: 80px !important;
   }
  // XX-Large devices (larger desktops, 1400px and up)
  @media (min-width: 1400px) { 
  font-size: 80px !important;
   }
`;
export const Text2 = styled.h2`
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  color: white;
  font-size: 20px !important;
  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) { 
    font-size: 40px !important;
   }
  // X-Large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) { 
    font-size: 40px !important;
   }
  // XX-Large devices (larger desktops, 1400px and up)
  @media (min-width: 1400px) { 
    font-size: 40px !important;
   }
`;
export const Text3 = styled.h3`
  font-family: 'Poppins';
  font-weight: bold;
  color: #231F20;
  font-size: 20px !important;
  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) { 
  font-size: 32px !important;
   }
  // X-Large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) { 
  font-size: 32px !important;
   }
  // XX-Large devices (larger desktops, 1400px and up)
  @media (min-width: 1400px) { 
  font-size: 32px !important;
   }
`;
export const Text4 = styled.h4`
  font-family: 'Montserrat', sans-serif;
  font-weight:  600;
  color: #7764E4;
  font-size: 14px !important;
  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) { 
  font-size: 18px !important;
   }
  // X-Large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) { 
  font-size: 18px !important;
   }
  // XX-Large devices (larger desktops, 1400px and up)
  @media (min-width: 1400px) { 
  font-size: 18px !important;
   }
`;
export const Text5 = styled.h5`
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  color: #5E5E5E;
  font-size: 12px !important;
  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) { 
  font-size: 16px !important;
   }
  // X-Large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) { 
  font-size: 16px !important;
   }
  // XX-Large devices (larger desktops, 1400px and up)
  @media (min-width: 1400px) { 
  font-size: 16px !important;
   }
`;
export const Text6 = styled.h6`
  font-family: 'Montserrat', sans-serif;
  font-weight: normal;
  color: #5E5E5E;
  font-size: 12px !important;
  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) { 
  font-size: 16px !important;
   }
  // X-Large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) { 
  font-size: 16px !important;
   }
  // XX-Large devices (larger desktops, 1400px and up)
  @media (min-width: 1400px) { 
  font-size: 16px !important;
   }
`;
export const GradientBackgroung = styled.div`
  display: flex;
  background: linear-gradient(180deg, rgba(2,0,36,1) 0%, rgba(255,255,255,1) 0%, rgba(238,238,238,1) 100%);
  max-width: 100%;
`;

export const SpaceContainer = styled(Container)`
  padding-top: 40px;
  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) { 
    margin-bottom: 100px;
   }
  // X-Large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) { 
    margin-bottom: 100px;
   }
  // XX-Large devices (larger desktops, 1400px and up)
  @media (min-width: 1400px) { 
    margin-bottom: 100px;
   }
`;

export const BlockSpacer = styled.div`
    height: 80px;
    background-color: transparent;
  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) { 
    height: 100px;
   }
  // X-Large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) { 
    height: 100px;
   }
  // XX-Large devices (larger desktops, 1400px and up)
  @media (min-width: 1400px) { 
    height: 100px;
   }
`;

export const GridContainer = styled(Container)`
  display: grid;
  grid-template-columns: auto;
  justify-items: stretch;
  grid-auto-rows: 30vh;
  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) { 
    grid-template-columns: auto auto;
    grid-auto-rows: 40vh;
   }
  // X-Large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) { 
    grid-template-columns: auto auto;
    grid-auto-rows: 40vh;
   }
  // XX-Large devices (larger desktops, 1400px and up)
  @media (min-width: 1400px) { 
    grid-template-columns: auto auto;
    grid-auto-rows: 40vh;
   }
`;
export const GridItem = styled.div`
  display: flex;
  padding: 20px;
  text-align: left;
  justify-content: center;
  flex-direction: column;
`;
export const GridImageTop = styled.div`
  background-image: url(${notebook});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
`;
export const GridImageBottom = styled.div`
  background-image: url(${celular});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  justify-self: stretch;
`;
export const ListGridContainer = styled.div`
  display: grid;
  grid-template-columns: auto;
  justify-items: stretch;
  margin-top: 60px;
  margin-bottom: 100px;
  grid-auto-rows: 10vh;
  grid-column-gap: 60px;
  grid-row-gap: 60px;
  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) { 
    grid-template-columns: auto auto;
    grid-auto-rows: 16vh;
    margin-top: 80px;
    margin-bottom: 120px;
   }
  // X-Large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) { 
    grid-template-columns: auto auto;
    grid-auto-rows: 16vh;
    margin-top: 80px;
    margin-bottom: 120px;
   }
  // XX-Large devices (larger desktops, 1400px and up)
  @media (min-width: 1400px) { 
    grid-template-columns: auto auto;
    grid-auto-rows: 16vh;
    margin-top: 80px;
    margin-bottom: 120px;
   }
`;
export const ListGridItem = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;
export const ListHolder = styled(Container)`
  padding-top: 40px;
  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) { 
  padding-top: 80px;
   }
  // X-Large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) { 
  padding-top: 80px;
   }
  // XX-Large devices (larger desktops, 1400px and up)
  @media (min-width: 1400px) { 
  padding-top: 120px;
   }
`;
export const IconTray = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #eeeeee;
  border-radius: 100%;
  height:8vh;
  width:8vh;
  filter: drop-shadow(1px 4px 4px darkgrey);
  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) { 
    height:14vh;
    width:14vh;
   }
  // X-Large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) { 
    height:14vh;
    width:14vh;
   }
  // XX-Large devices (larger desktops, 1400px and up)
  @media (min-width: 1400px) { 
    height:14vh;
    width:14vh;
   }
`;
export const FlexDiv = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: left;
  flex-direction: column;
  width: calc(100% - 10vh);
  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) { 
  width: calc(100% - 17vh);
   }
  // X-Large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) { 
  width: calc(100% - 17vh);
   }
  // XX-Large devices (larger desktops, 1400px and up)
  @media (min-width: 1400px) { 
  width: calc(100% - 17vh);
   }
`;
export const ColorDiv = styled.div`
  background-color: #eeeeee;
`;
export const SimpleContainer = styled(Container)`
  display: grid;
  grid-template-columns: auto;
  justify-items: stretch;
  grid-auto-rows: 50vh;
  align-items: center;
  grid-column-gap: 90px;
  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) { 
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 80vh;
   }
  // X-Large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) { 
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 80vh;
   }
  // XX-Large devices (larger desktops, 1400px and up)
  @media (min-width: 1400px) { 
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 80vh;
   }
`;
export const ImageHolder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  height: 33vh;
  filter: drop-shadow(1px 4px 4px darkgrey);
  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576px) {
    height: 36vh;
 }
  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    height: 46vh;
 }
  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) { 
    height: 50vh;
  }
  // X-Large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) { 
    height: 60vh;
  }

`;
export const ImageItem = styled.div`
  height: 55vh;
  width: 85%;
  background-image: url(${demo});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  filter: drop-shadow(1px 4px 4px darkgrey);
  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576px) {
    width: 75%;
 }
  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    width: 65%;
 }
  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) { 
    width: 90%;
  }
  // X-Large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) { 
    width: 90%;
  }
`;
export const BorderText3 = styled.h3`
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  color: #5E5E5E;
  margin-bottom: 40px;
  font-size: 20px !important;
  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) { 
  font-size: 32px !important;
   }
  // X-Large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) { 
  font-size: 32px !important;
   }
  // XX-Large devices (larger desktops, 1400px and up)
  @media (min-width: 1400px) { 
  font-size: 32px !important;
   }
`;
export const EndGridItem = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;
export const FormContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-items: stretch;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  grid-auto-rows: 50px;
  padding-bottom: 20px;
  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) { 
    grid-template-columns: 1fr 1fr;
   }
  // X-Large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) { 
    grid-template-columns: 1fr 1fr;
   }
  // XX-Large devices (larger desktops, 1400px and up)
  @media (min-width: 1400px) { 
    grid-template-columns: 1fr 1fr;
   }
`;
export const FormEnd = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 30px;
  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) { 
   grid-area: 3 / 3 / span 2 / span 2;
   }
  // X-Large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) { 
    grid-area: 3 / 3 / span 2 / span 2;
   }
  // XX-Large devices (larger desktops, 1400px and up)
  @media (min-width: 1400px) { 
    grid-area: 3 / 3 / span 2 / span 2;
   }
`;
export const ProductContainer = styled.div`
  display: flex;
  align-items: flex-end;
  background-image: url(${devpic2});
  height: 12vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  max-width: 100%;
  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) { 
  height: 30vh !important;
   }
  // X-Large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) { 
  height: 30vh !important;
   }
  // XX-Large devices (larger desktops, 1400px and up)
  @media (min-width: 1400px) { 
  height: 30vh !important;
   }
`;
export const AboutContainer = styled.div`
  display: flex;
  align-items: flex-end;
  background-image: url(${devpic});
  height: 12vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  max-width: 100%;
  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) { 
  height: 30vh !important;
   }
  // X-Large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) { 
  height: 30vh !important;
   }
  // XX-Large devices (larger desktops, 1400px and up)
  @media (min-width: 1400px) { 
  height: 30vh !important;
   }
`;
export const ListWhite = styled(Container)`
  padding-bottom: 40px;
  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) { 
  padding-bottom: 80px;
   }
  // X-Large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) { 
  padding-bottom: 80px;
   }
  // XX-Large devices (larger desktops, 1400px and up)
  @media (min-width: 1400px) { 
  padding-bottom: 120px;
   }
`;
export const TextP = styled.p`
  font-family: 'Poppins';
  font-weight: normal;
  color: #231F20;
  font-size: 12px !important;
  line-height: 2;
  margin-top: 20px;
  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) { 
  font-size: 16px !important;
   }
  // X-Large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) { 
  font-size: 16px !important;
   }
  // XX-Large devices (larger desktops, 1400px and up)
  @media (min-width: 1400px) { 
  font-size: 16px !important;
   }
`;
export const ProductSpaceContainer = styled(Container)`
  padding-top: 40px;
  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) { 
    margin-bottom: 20px;
   }
  // X-Large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) { 
    margin-bottom: 20px;
   }
  // XX-Large devices (larger desktops, 1400px and up)
  @media (min-width: 1400px) { 
    margin-bottom: 20px;
   }
`;