import "bootstrap/dist/css/bootstrap.min.css";

import { Slide, ToastContainer } from "react-toastify";

import Footer from "./components/Footer";
import { GlobalStyle } from "./styles/GlobalStyles";
import Router from "./router";

function App() {
    return (
        <div className="App">
            <ToastContainer
                autoClose={4000}
                position="bottom-left"
                limit={5}
                transition={Slide}
            />

            <GlobalStyle />

            <Router />

            <Footer />
        </div>
    );
}

export default App;
