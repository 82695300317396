import { Container, Loader } from "./styles";

import React from "react";

export const LoadingComponent = (props) => {
    return (
        <Container
            background={props.background}
            absolute={props.absolute}
            fixed={props.fixed}
        >
            <Loader
                usePurpleColor={props.usePurpleColor}
                button={props.button}
                color={props.color}
                size={props.size}
            />
        </Container>
    );
};
