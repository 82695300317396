import React from "react";
import * as SC from "../../styles/styles";
import { HomePageProvider } from "./hooks/useHomePage";
import TextPack from "../../components/TextPack";
import TabletDisplay from "../../components/TabletDisplay";
import { PiHandTap, PiPhoneCall, PiEnvelope, PiInstagramLogo, PiLinkedinLogo } from "react-icons/pi";
import Trio from "./../../images/trio.png";
import BackArt from "./../../images/back_art.svg";
import Art1 from "./../../images/half_square.svg";
import Art2 from "./../../images/half_losangle.svg";
import Mac from "./../../images/notebook_cut.png";
import TextPackDark from "../../components/TextPackDark";
import Art3 from "./../../images/half_circle.svg";
import Art4 from "./../../images/half_triangle.svg";
import Operador from "./../../images/operador_phone.png";
import Limpeza from "./../../images/limpeza_phone.png";
import Ilumina from "./../../images/ilumina_phone.png";
import PhoneCard from "../../components/PhoneCard";
import Contato from "./components/Contato";
import Telegestao from "./../../images/telegestao_tab.png";
import Arborizacao from "./../../images/arborizacao_tab.png";
import Intranet from "./../../images/intranet_tab.png";
import Projetos from "./../../images/projetos_tab.png";
import Residuos from "./../../images/veiculos_tab.png";
import Iluminacao from "./../../images/ocorrencias_tab.png";

export const Home = () => {
  const [showContent, setShowContent] = React.useState(0);

  return (
    <>
      <HomePageProvider>
        <SC.DarkSection id="servicos">
          <TextPack
            title='Todos os serviços em um único lugar'
            subtitle={
              <>
              Unificamos todos os serviços num único lugar, no computador,&nbsp;
              <br/>
              no celular e no tablet. Em todo lugar é possível!
              </>
            }
          />
          <TabletDisplay 
            src={showContent === 0 ? Iluminacao : 
              showContent === 1 ? Arborizacao : 
              showContent === 2 ? Intranet : 
              showContent === 3 ? Residuos :
              showContent === 4 ? Projetos :
              showContent === 5 ? Telegestao :Telegestao
          }>
            <SC.ButtonsFloat>
              <SC.ButtonWrapper>
                <SC.ButtonSwapper active={showContent === 0 ? true : false} onClick={()=> setShowContent(0)}>
                  Iluminação pública
                </SC.ButtonSwapper>
                <SC.ButtonSwapper active={showContent === 1 ? true : false} onClick={()=> setShowContent(1)}>
                  Arborização
                </SC.ButtonSwapper>
              </SC.ButtonWrapper>
              <SC.ButtonWrapper2>
                <SC.ButtonSwapper active={showContent === 2 ? true : false} onClick={()=> setShowContent(2)}>
                  Intranet
                </SC.ButtonSwapper>
                <SC.ButtonSwapper active={showContent === 3 ? true : false} onClick={()=> setShowContent(3)}>
                  Gestão de resíduos
                </SC.ButtonSwapper>
              </SC.ButtonWrapper2>
              <SC.ButtonWrapper3>
                <SC.ButtonSwapper active={showContent === 4 ? true : false} onClick={()=> setShowContent(4)}>
                  Gestão de projetos
                </SC.ButtonSwapper>
                <SC.ButtonSwapper active={showContent === 5 ? true : false} onClick={()=> setShowContent(5)}>
                  Telegestão
                </SC.ButtonSwapper>
              </SC.ButtonWrapper3>
              <PiHandTap />
            </SC.ButtonsFloat>
          </TabletDisplay>
        </SC.DarkSection>
        <SC.PurpleStripe>
          <SC.TextDuo>
            <h1>+41.285</h1>
            <h2>Postes de iluminação inteligentes monitorados</h2>
          </SC.TextDuo>
          <SC.TextDuo>
            <h1>+5.792</h1>
            <h2>Ocorrências geradas nos últimos 30 dias</h2>
          </SC.TextDuo>
          <SC.TextDuo>
            <h1>+2.200.000</h1>
            <h2>Munícipes satisfeitos com as soluções</h2>
          </SC.TextDuo>
        </SC.PurpleStripe>
        <SC.DarkSection2 id="sobre">
          <TextPack
            title='Um novo mundo de possibilidades'
            subtitle={
              <>
              Produtos inovadores na área de gestão urbana, tratando de&nbsp;
              <br/>
              assuntos como a acessibilidade de forma séria
              </>
            }
          />
          <img src={Trio} className='bigphoto'/>
          <img src={BackArt} className='backphoto'/>
        </SC.DarkSection2>
        <SC.WhiteSection>
          <TextPackDark
            title='Conheça o SGI'
            subtitle={
              <>
              A ferramenta que muda o jeito de gerir,&nbsp;
              <br/>
              economizar e otimizar com qualidade
              </>
            }
          />
          <SC.Label>
            *Sistema de gestão integrada
          </SC.Label>
          <img src={Art1} className='art1'/>
          <img src={Art2} className='art2'/>
          <img src={Mac} className='main'/>
        </SC.WhiteSection>
        <SC.DarkSection3 id="produtos">
          <img src={Art3} className='art3'/>
          <img src={Art4} className='art4'/>

          <TextPack
            title='Descubra os aplicativos disponíveis'
            subtitle={
              <>
              Dispomos de uma gama variada de aplicativos de distribuição pública e corporativa.&nbsp;
              <br/>
              Disponibilizados nas lojas oficiais do Android e iOS. 
              </>
            }
          />
          <SC.CardsContainer>
            <PhoneCard label='Ilumina' src={Ilumina} />
            <PhoneCard label='Limpeza urbana' src={Limpeza} />
            <PhoneCard label='Operador' src={Operador} />
          </SC.CardsContainer>
        </SC.DarkSection3>

        <SC.ColorStripe1 />
        <SC.ColorStripe2 />
        <SC.ColorStripe3 />
        <SC.ColorStripe4 />

        <SC.DarkSection4  id="contato">
         <TextPack
            title='Entre em contato'
            subtitle={
              <>
              Quer saber mais ou precisa de um orçamento? Entre em contato ou acesse&nbsp;
              <br/>
              os portais oficiais da Mosaro
              </>
            }
          />
          <SC.ColumnContainer>
            <SC.SmallColumn>
              <SC.SmallColumnSection>
                <SC.TopicTitle>
                  Canais diretos
                </SC.TopicTitle>
                <SC.IconText>
                  <PiEnvelope />
                  <label>contato@mosaro.com.br</label>
                </SC.IconText>
                {/*<SC.IconText>
                  <PiPhoneCall />
                  <label>+55 81 99789-9997</label>
                </SC.IconText>*/}
              </SC.SmallColumnSection>
              <SC.SmallColumnSection>
                <SC.TopicTitle>
                  Redes sociais
                </SC.TopicTitle>
                <SC.IconRow>
                  <a href="https://www.linkedin.com/company/mosarotech/about/?viewAsMember=true">
                    <PiLinkedinLogo/>
                  </a>
                  <a href="https://www.instagram.com/mosarosolucoes">
                    <PiInstagramLogo />
                  </a>
                </SC.IconRow>
              </SC.SmallColumnSection>
            </SC.SmallColumn>

            <SC.LargeColumn>
              <SC.TopicTitle>
                Envie uma mensagem
              </SC.TopicTitle>
              <Contato />

            </SC.LargeColumn>
          </SC.ColumnContainer>
        </SC.DarkSection4>
      </HomePageProvider>
    </>
  );
};
