import {
    getEstados,
    getMunicipiosByEstadoID,
} from "../../../../../services/localidade";

import React from "react";
import { useHomePage } from "../../../hooks/useHomePage";

const useLoaderDataContato = () => {
    const { state, actions } = useHomePage();

    const [estados, setEstados] = React.useState([]);
    const [municipios, setMunicipios] = React.useState([]);

    const loadEstados = React.useCallback(async() => {
        try {
            const response = await getEstados();

            setEstados(response.data);
        } catch (err) {
            setEstados([]);
            actions.setForm((state) => ({
                ...state,
                estado: 0,
            }));
        }
    }, [setEstados, actions.setForm]);

    const loadMunicipios = React.useCallback(async() => {
        try {
            const response = await getMunicipiosByEstadoID(state.form.estado);

            setMunicipios(response.data);
        } catch (err) {
            setMunicipios([]);
            actions.setForm((state) => ({
                ...state,
                municipio: 0,
            }));
        }
    }, [setMunicipios, state.form.estado, actions.setForm]);

    React.useEffect(() => {
        actions.setLoading(true);

        const promises = [loadEstados()];

        Promise.all(promises).finally(() => actions.setLoading(false));
    }, []);

    React.useEffect(() => {
        if (state.form.estado > 0) {
            actions.setLoading(true);

            const promises = [loadMunicipios()];

            Promise.all(promises).finally(() => actions.setLoading(false));
        } else {
            setMunicipios([]);
            actions.setForm((state) => ({
                ...state,
                municipio: 0,
            }));
        }
    }, [state.form.estado]);

    return {
        estados,
        municipios,
    };
};

export default useLoaderDataContato;